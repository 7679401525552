import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Form, Tree, Tooltip, Select, Empty, Button } from "antd"
import { Collapse } from 'antd';
import { FolderOpenOutlined, FilterOutlined, TeamOutlined, CaretRightOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import FilterView from "../../../../../../component/FilterView/filterView";
import { getGroupList } from "../../../../../../api/UserInsight/UserInsight";
import type { GetProps, TreeDataNode } from 'antd';

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;
const { DirectoryTree } = Tree;
let GroupMes = forwardRef((props: {
}, ref: any) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        resetData,
        getGroupMes,
        resetFilter
    }))
    const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId') : '';
    const filterViewRef: any = useRef(null)
    const [form] = Form.useForm();
    const [peopleUserOptions, setPeopleUserOptions] = useState([])
    const groupListName = [
        {name:'MA',value:'营销创建'},
        {name:'RULE',value:'规则创建'},
        {name:'RFM',value:'RFM创建'},
        {name:'EXPORT',value:'导入创建'},
    ]
    const [groupMesList, setGroupMesList] = useState(
        [] as any
    );
    const [iconColor, setIconColor] = useState('var(--text-font-color4)');
    const handleMouseEnter = () => {
        // console.log('鼠标移入')
        setIconColor('var(--text-font-color9)');
    };
    const handleMouseLeave = () => {
        // console.log('鼠标移出')
        setIconColor('var(--text-font-color4)');
    };
    const panelStyle: React.CSSProperties = {
        marginBottom: 0,
        background: 'var(--background1)',
        border: 'none',
    };
    //重置搜索数据
    const resetData = () => {
        form.resetFields();
    }
    // 获取所在人群 
    const getGroupMes = async (
        data?: any,
    ) => {
        const param = {
            oneId: oneId,
            ...data,
        } as any;
        let res:any;
        res = await getGroupList(param);
        if (res) {
            var list:any = []
            res.data.map((item: any, index: any) => {
                groupListName.map((i:any)=>{
                    if (i.name == item.name) {
                        item.name = i.value
                    }
                })
                list.push({
                    key: index,
                    label:<>
                        <FolderOpenOutlined style={{marginRight:'8px'}}/>
                        {item.name+ '（' + item.crowdList.length + '）'}
                    </> ,
                    children: <div className={style["GroupMes_peopleListBox"]} key={index}>
                        {item.crowdList.map((itm: any, indx: any) => (
                            <div className={style['GroupMes_Item']} key={indx}>
                                <TeamOutlined style={{ color: 'var(--text-font-color7)', marginRight: '8px' }}></TeamOutlined>
                                <Tooltip placement="top" title={itm.crowdName}>
                                    <div className={style['GroupMes_Item-text']}>{itm.crowdName}</div>
                                </Tooltip>
                            </div>
                        ))}
                    </div>,
                    style: panelStyle,
                })
            })
            setGroupMesList(list)
        }
    };

    // 查询
    const filterFinish = (data: any) => {
        getGroupMes(data);
    };
    //搜索条件
    const searchList: searchItem[] = [
        {
            searchType: "text",
            searchComponentType: "input",
            placeHolder: "请选择人群",
            title: "人群查询",
            key: "crowdName",
        },
    ];
    //重置筛选框
    const resetFilter = () => {
        filterViewRef.current?.resetData();
    };
    //监听或进入调用
    useEffect(() => {
        getGroupMes()
        filterViewRef.current?.showFilterView();
    }, [])

    return <>
        <div className={style['GroupMes']}>
            <div className={style['GroupMes_serch-box']}>
                <Tooltip placement="bottom" title={'过滤器'}>
                    <Button
                        onClick={() => filterViewRef.current?.showFilterView()}
                        icon={
                            <FilterOutlined
                                style={{
                                    color: iconColor,
                                    fontSize: 'var(--text-font7)',
                                }}
                            />
                        }
                        type="text"
                        className="click-element"
                        style={{ marginRight: '10px' }}
                        onMouseLeave={handleMouseLeave}
                        onMouseEnter={handleMouseEnter}></Button>
                </Tooltip>
            </div>
            <FilterView
                averageTwo={true}
                ref={filterViewRef}
                searchList={searchList}
                finish={filterFinish}
            ></FilterView>
            <Collapse
                bordered={false}
                defaultActiveKey={[]}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                // style={{ background: token.colorBgContainer }}
                items={groupMesList}
            />
            {/* <div style={{ marginTop: '16px' }}>
                {groupMesList.map((item: any, index: any) => (
                    <>
                        <div>{item.name}（{item.crowdList.length}）</div>
                        <div className={style["GroupMes_peopleListBox"]} key={index}>
                            {item.crowdList.map((itm: any, indx: any) => (
                                <div className={style['GroupMes_Item']} key={indx}>
                                    <TeamOutlined style={{ color: 'var(--text-font-color7)', marginRight: '8px' }}></TeamOutlined>
                                    <Tooltip placement="top" title={itm.crowdName}>
                                        <div className={style['GroupMes_Item-text']}>{itm.crowdName}</div>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div> */}
            {!groupMesList || groupMesList.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{marginTop:'150px'}}/>}
        </div>
    </>
})
export default GroupMes
